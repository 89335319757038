<template>
    <div class="content-op-dashboard">
        <v-expansion-panels class="expansion-panels-op-dashboard" flat>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Monitores
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <!-- #region Dashboard MXN -->
                    <div class="content-dashboard-info">
                        <div class="content-dashboard-oc" :class="`content-dashboard-oc-${sNameRotue}`">
                            <div v-if="bOPGeneralView || bOPPendingView" class="content-finished">
                                <div class="content-text">
                                    <span class="info-text">
                                        <!-- Órdenes de compra finalizadas -->
                                        {{ sLabelOC1Fixed + sLabelOC1 }}
                                    </span>
                                </div>
                                <div class="content-icon-number" :class="`content-icon-number-${sNameRotue}`">
                                    <div class="content-btn">
                                        <v-btn depressed class="btn-icon-pound">
                                            <span class="material-icons icon-pound-dash">
                                                tag
                                            </span>
                                        </v-btn>
                                    </div>
                                    <div class="content-number">
                                        <span v-if="ObjEmptyGlobal(oPurchaseOrders)" class="number-text">
                                            {{ oPurchaseOrders.iTotal }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="bOPGeneralView || bOPPayable" class="content-paid">
                                <div class="content-text">
                                    <span class="info-text">
                                        <!-- Monto acumulado por OC pagados -->
                                        {{ sLabelOC2Fixed + sLabelOC2 }}

                                    </span>
                                </div>
                                <div class="content-icon-number" :class="`content-icon-number-${sNameRotue}`">
                                    <div class="content-btn">
                                        <v-btn depressed class="btn-icon-currency">
                                            <span class="material-icons icon-currency-dash">
                                                attach_money
                                            </span>
                                        </v-btn>
                                    </div>
                                    <div class="content-number">
                                        <span v-if="ObjEmptyGlobal(oPurchaseOrders)" class="number-text">
                                            $ {{ formatMoneyGlobal(oPurchaseOrders.dTotal) }} MXN
                                        </span>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <v-spacer></v-spacer>
                        <div v-if="bOPGeneralView || bOPPayable" class="content-dashboard-weighing-machine-public"
                            :class="`content-dashboard-weighing-machine-public-${sNameRotue}`">
                            <div v-if="bOPGeneralView || bOPPendingView" class="content-finished">
                                <div class="content-text">
                                    <span class="info-text">
                                        <!-- Órdenes de compra finalizadas -->
                                        {{ sLabelWP1Fixed + sLabelWP1 }}
                                    </span>
                                </div>
                                <div class="content-icon-number" :class="`content-icon-number-${sNameRotue}`">
                                    <div class="content-btn">
                                        <v-btn depressed class="btn-icon-pound">
                                            <span class="material-icons icon-pound-dash">
                                                tag
                                            </span>
                                        </v-btn>
                                    </div>
                                    <div class="content-number">
                                        <span v-if="ObjEmptyGlobal(oWeighingMachinePublic)" class="number-text">
                                            {{ oWeighingMachinePublic.iTotal }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="bOPGeneralView || bOPPayable" class="content-paid">
                                <div class="content-text">
                                    <span class="info-text">
                                        <!-- Monto acumulado por OC pagados -->
                                        {{ sLabelWP2Fixed + sLabelWP2 }}

                                    </span>
                                </div>
                                <div class="content-icon-number" :class="`content-icon-number-${sNameRotue}`">
                                    <div class="content-btn">
                                        <v-btn depressed class="btn-icon-currency">
                                            <span class="material-icons icon-currency-dash">
                                                attach_money
                                            </span>
                                        </v-btn>
                                    </div>
                                    <div class="content-number">
                                        <span v-if="ObjEmptyGlobal(oWeighingMachinePublic)" class="number-text">
                                            $ {{ formatMoneyGlobal(oWeighingMachinePublic.dTotal) }} MXN
                                        </span>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="content-dashboard-so" :class="`content-dashboard-so-${sNameRotue}`">
                            <div v-if="bOPGeneralView || bOPPendingView" class="content-finished">
                                <div class="content-text">
                                    <span class="info-text">
                                        <!-- Pedidos de venta finalizados -->
                                        {{ sLabelPV1Fixed + sLabelPV1 }}

                                    </span>
                                </div>
                                <div class="content-icon-number" :class="`content-icon-number-${sNameRotue}`">
                                    <div class="content-btn">
                                        <v-btn depressed class="btn-icon-pound">
                                            <span class="material-icons icon-pound-dash">
                                                tag
                                            </span>
                                        </v-btn>
                                    </div>
                                    <div class="content-number">
                                        <span v-if="ObjEmptyGlobal(oSalesOrders)" class="number-text">
                                            {{ oSalesOrders.iTotal }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="bOPGeneralView || bOPPayable" class="content-paid">
                                <div class="content-text">
                                    <span class="info-text">
                                        <!-- Monto acumulado por PV pagados -->
                                        {{ sLabelPV2Fixed + sLabelPV2 }}
                                    </span>
                                </div>
                                <div class="content-icon-number" :class="`content-icon-number-${sNameRotue}`">
                                    <div class="content-btn">
                                        <v-btn depressed class="btn-icon-currency">
                                            <span class="material-icons icon-currency-dash">
                                                attach_money
                                            </span>
                                        </v-btn>
                                    </div>
                                    <div class="content-number">
                                        <span v-if="ObjEmptyGlobal(oSalesOrders)" class="number-text">
                                            $ {{ formatMoneyGlobal(oSalesOrders.dTotal) }} MXN
                                        </span>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <!-- #endregion Dashboard MXN -->

                    <!-- #region Dashboard USD -->
                    <div class="content-dashboard-info">
                        <div class="content-dashboard-oc" :class="`content-dashboard-oc-${sNameRotue}`">
                            <div v-if="bOPGeneralView || bOPPendingView" class="content-finished">
                                <div class="content-text">
                                    <span class="info-text">
                                        <!-- Órdenes de compra finalizadas -->
                                        {{ sLabelOC1Fixed + sLabelOC1 }}
                                    </span>
                                </div>
                                <div class="content-icon-number" :class="`content-icon-number-${sNameRotue}`">
                                    <div class="content-btn">
                                        <v-btn depressed class="btn-icon-pound">
                                            <span class="material-icons icon-pound-dash">
                                                tag
                                            </span>
                                        </v-btn>
                                    </div>
                                    <div class="content-number">
                                        <span v-if="ObjEmptyGlobal(oPurchaseOrders)" class="number-text">
                                            {{ oPurchaseOrdersUSD.iTotal }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="bOPGeneralView || bOPPayable" class="content-paid">
                                <div class="content-text">
                                    <span class="info-text">
                                        <!-- Monto acumulado por OC pagados -->
                                        {{ sLabelOC2Fixed + sLabelOC2 }}

                                    </span>
                                </div>
                                <div class="content-icon-number" :class="`content-icon-number-${sNameRotue}`">
                                    <div class="content-btn">
                                        <v-btn depressed class="btn-icon-currency">
                                            <span class="material-icons icon-currency-dash">
                                                attach_money
                                            </span>
                                        </v-btn>
                                    </div>
                                    <div class="content-number">
                                        <span v-if="ObjEmptyGlobal(oPurchaseOrdersUSD)" class="number-text">
                                            $ {{ formatMoneyGlobal(oPurchaseOrdersUSD.dTotal) }} USD
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <v-spacer></v-spacer>
                        <div v-if="bOPGeneralView || bOPPayable" class="content-dashboard-weighing-machine-public"
                            :class="`content-dashboard-weighing-machine-public-${sNameRotue}`">
                            <div v-if="bOPGeneralView || bOPPendingView" class="content-finished">
                                <div class="content-text">
                                    <span class="info-text">
                                        <!-- Órdenes de compra finalizadas -->
                                        {{ sLabelWP1Fixed + sLabelWP1 }}
                                    </span>
                                </div>
                                <div class="content-icon-number" :class="`content-icon-number-${sNameRotue}`">
                                    <div class="content-btn">
                                        <v-btn depressed class="btn-icon-pound">
                                            <span class="material-icons icon-pound-dash">
                                                tag
                                            </span>
                                        </v-btn>
                                    </div>
                                    <div class="content-number">
                                        <span v-if="ObjEmptyGlobal(oWeighingMachinePublicUSD)" class="number-text">
                                            {{ oWeighingMachinePublicUSD.iTotal }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="bOPGeneralView || bOPPayable" class="content-paid">
                                <div class="content-text">
                                    <span class="info-text">
                                        <!-- Monto acumulado por OC pagados -->
                                        {{ sLabelWP2Fixed + sLabelWP2 }}

                                    </span>
                                </div>
                                <div class="content-icon-number" :class="`content-icon-number-${sNameRotue}`">
                                    <div class="content-btn">
                                        <v-btn depressed class="btn-icon-currency">
                                            <span class="material-icons icon-currency-dash">
                                                attach_money
                                            </span>
                                        </v-btn>
                                    </div>
                                    <div class="content-number">
                                        <span v-if="ObjEmptyGlobal(oWeighingMachinePublicUSD)" class="number-text">
                                            $ {{ formatMoneyGlobal(oWeighingMachinePublicUSD.dTotal) }} USD
                                        </span>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="content-dashboard-so" :class="`content-dashboard-so-${sNameRotue}`">
                            <div v-if="bOPGeneralView || bOPPendingView" class="content-finished">
                                <div class="content-text">
                                    <span class="info-text">
                                        <!-- Pedidos de venta finalizados -->
                                        {{ sLabelPV1Fixed + sLabelPV1 }}

                                    </span>
                                </div>
                                <div class="content-icon-number" :class="`content-icon-number-${sNameRotue}`">
                                    <div class="content-btn">
                                        <v-btn depressed class="btn-icon-pound">
                                            <span class="material-icons icon-pound-dash">
                                                tag
                                            </span>
                                        </v-btn>
                                    </div>
                                    <div class="content-number">
                                        <span v-if="ObjEmptyGlobal(oSalesOrdersUSD)" class="number-text">
                                            {{ oSalesOrdersUSD.iTotal }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="bOPGeneralView || bOPPayable" class="content-paid">
                                <div class="content-text">
                                    <span class="info-text">
                                        <!-- Monto acumulado por PV pagados -->
                                        {{ sLabelPV2Fixed + sLabelPV2 }}
                                    </span>
                                </div>
                                <div class="content-icon-number" :class="`content-icon-number-${sNameRotue}`">
                                    <div class="content-btn">
                                        <v-btn depressed class="btn-icon-currency">
                                            <span class="material-icons icon-currency-dash">
                                                attach_money
                                            </span>
                                        </v-btn>
                                    </div>
                                    <div class="content-number">
                                        <span v-if="ObjEmptyGlobal(oSalesOrdersUSD)" class="number-text">
                                            $ {{ formatMoneyGlobal(oSalesOrdersUSD.dTotal) }} USD
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- #endregion Dashboard USD -->
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

    </div>
</template>

<script>
export default {
    props: {
        oItemDashboard: Object
    },
    data() {
        return {
            oItemDashboardEdit: {},
            oPurchaseOrders: {},
            oWeighingMachinePublic: {},
            oSalesOrders: {},
            Prop1: "dTotal",
            Prop2: "iTotal",

            oItemDashboardEditUSD: {},
            oPurchaseOrdersUSD: {},
            oWeighingMachinePublicUSD: {},
            oSalesOrdersUSD: {},
            Prop1USD: "dTotal",
            Prop2USD: "iTotal",

            sLabelOC1Fixed: "Órdenes de compra",
            sLabelOC2Fixed: "Monto acumulado por OC",

            sLabelWP1Fixed: "Báscula pública",
            sLabelWP2Fixed: "Monto acumulado por BP",

            sLabelPV1Fixed: "Pedidos de venta",
            sLabelPV2Fixed: "Monto acumulado por PV",

            sLabelOC1: "",
            sLabelOC2: "",

            sLabelWP1: "",
            sLabelWP2: "",

            sLabelPV1: "",
            sLabelPV2: "",
        }
    },
    beforeMount() {
        this.setItemDashboard();
        this.setLabelsDashboard();
    },
    destroyed() {
        this.oItemDashboardEdit = {};
        this.oPurchaseOrders = {};
        this.oWeighingMachinePublic = {};
        this.oSalesOrders = {};
    },
    methods: {
        setItemDashboard() {
            if (this.ObjEmptyGlobal(this.oItemDashboard)) {
                this.oItemDashboardEdit = this.oItemDashboard
                switch (this.$route.name) {
                    case "OPGeneral":
                        this.oPurchaseOrders = {
                            [this.Prop1]: this.oItemDashboardEdit.oFinalizedPurchaseorders.oTotalAmount.dMexicanPeso ? this.oItemDashboardEdit.oFinalizedPurchaseorders.oTotalAmount.dMexicanPeso : 0,
                            [this.Prop2]: this.oItemDashboardEdit.oFinalizedPurchaseorders.iTotalFinalizedMXN ? this.oItemDashboardEdit.oFinalizedPurchaseorders.iTotalFinalizedMXN : 0
                        }
                        this.oWeighingMachinePublic = {
                            [this.Prop1]: this.oItemDashboardEdit.oFinalizedPublicOrders.oTotalAmount.dMexicanPeso ? this.oItemDashboardEdit.oFinalizedPublicOrders.oTotalAmount.dMexicanPeso : 0,
                            [this.Prop2]: this.oItemDashboardEdit.oFinalizedPublicOrders.iTotalFinalizedMXN ? this.oItemDashboardEdit.oFinalizedPublicOrders.iTotalFinalizedMXN : 0
                        }
                        this.oSalesOrders = {
                            [this.Prop1]: this.oItemDashboardEdit.oFinalizedSalesOrders.oTotalAmount.dMexicanPeso ? this.oItemDashboardEdit.oFinalizedSalesOrders.oTotalAmount.dMexicanPeso : 0,
                            [this.Prop2]: this.oItemDashboardEdit.oFinalizedSalesOrders.iTotalFinalizedMXN ? this.oItemDashboardEdit.oFinalizedSalesOrders.iTotalFinalizedMXN : 0
                        }

                        this.oPurchaseOrdersUSD = {
                            [this.Prop1]: this.oItemDashboardEdit.oFinalizedPurchaseorders.oTotalAmount.dUSDollar ? this.oItemDashboardEdit.oFinalizedPurchaseorders.oTotalAmount.dUSDollar : 0,
                            [this.Prop2]: this.oItemDashboardEdit.oFinalizedPurchaseorders.iTotalFinalizedUSD ? this.oItemDashboardEdit.oFinalizedPurchaseorders.iTotalFinalizedUSD : 0
                        }
                        this.oWeighingMachinePublicUSD = {
                            [this.Prop1]: this.oItemDashboardEdit.oFinalizedPublicOrders.oTotalAmount.dUSDollar ? this.oItemDashboardEdit.oFinalizedPublicOrders.oTotalAmount.dUSDollar : 0,
                            [this.Prop2]: this.oItemDashboardEdit.oFinalizedPublicOrders.iTotalFinalizedUSD ? this.oItemDashboardEdit.oFinalizedPublicOrders.iTotalFinalizedUSD : 0
                        }
                        this.oSalesOrdersUSD = {
                            [this.Prop1]: this.oItemDashboardEdit.oFinalizedSalesOrders.oTotalAmount.dUSDollar ? this.oItemDashboardEdit.oFinalizedSalesOrders.oTotalAmount.dUSDollar : 0,
                            [this.Prop2]: this.oItemDashboardEdit.oFinalizedSalesOrders.iTotalFinalizedUSD ? this.oItemDashboardEdit.oFinalizedSalesOrders.iTotalFinalizedUSD : 0
                        }
                        break;
                    case "OPPending":
                        this.oPurchaseOrders = {
                            [this.Prop1]: 0,
                            [this.Prop2]: this.oItemDashboardEdit.oPendingPurchaseOrders.iTotalPending ? this.oItemDashboardEdit.oPendingPurchaseOrders.iTotalPending : 0
                        }
                        this.oSalesOrders = {
                            [this.Prop1]: 0,
                            [this.Prop2]: this.oItemDashboardEdit.oPendingSalesOrders.iTotalPending ? this.oItemDashboardEdit.oPendingSalesOrders.iTotalPending : 0
                        }

                        this.oPurchaseOrdersUSD = {
                            [this.Prop1]: 0,
                            [this.Prop2]: this.oItemDashboardEdit.oPendingPurchaseOrders.iTotalPending ? this.oItemDashboardEdit.oPendingPurchaseOrders.iTotalPending : 0
                        }
                        this.oSalesOrdersUSD = {
                            [this.Prop1]: 0,
                            [this.Prop2]: this.oItemDashboardEdit.oPendingSalesOrders.iTotalPending ? this.oItemDashboardEdit.oPendingSalesOrders.iTotalPending : 0
                        }
                        break;
                    case "OPPayable":
                        if (this.sTabPositionOrderPayable === 0) {
                            this.oPurchaseOrders = {
                                [this.Prop1]: this.oItemDashboardEdit.oPendingPurchaseOrders.oTotals.oMexicanPeso.dTotalAmount ? this.oItemDashboardEdit.oPendingPurchaseOrders.oTotals.oMexicanPeso.dTotalAmount : 0,
                                [this.Prop2]: 0
                            }
                            this.oWeighingMachinePublic = {
                                [this.Prop1]: this.oItemDashboardEdit.oPendingPublicOrders.oTotals.oMexicanPeso.dTotalAmount ? this.oItemDashboardEdit.oPendingPublicOrders.oTotals.oMexicanPeso.dTotalAmount : 0,
                                [this.Prop2]: 0
                            }
                            this.oSalesOrders = {
                                [this.Prop1]: this.oItemDashboardEdit.oPendingSalesOrders.oTotals.oMexicanPeso.dTotalAmount ? this.oItemDashboardEdit.oPendingSalesOrders.oTotals.oMexicanPeso.dTotalAmount : 0,
                                [this.Prop2]: 0
                            }

                            this.oPurchaseOrdersUSD = {
                                [this.Prop1]: this.oItemDashboardEdit.oPendingPurchaseOrders.oTotals.oUSDollar.dTotalAmount ? this.oItemDashboardEdit.oPendingPurchaseOrders.oTotals.oUSDollar.dTotalAmount : 0,
                                [this.Prop2]: 0
                            }
                            this.oWeighingMachinePublicUSD = {
                                [this.Prop1]: this.oItemDashboardEdit.oPendingPublicOrders.oTotals.oUSDollar.dTotalAmount ? this.oItemDashboardEdit.oPendingPublicOrders.oTotals.oUSDollar.dTotalAmount : 0,
                                [this.Prop2]: 0
                            }
                            this.oSalesOrdersUSD = {
                                [this.Prop1]: this.oItemDashboardEdit.oPendingSalesOrders.oTotals.oUSDollar.dTotalAmount ? this.oItemDashboardEdit.oPendingSalesOrders.oTotals.oUSDollar.dTotalAmount : 0,
                                [this.Prop2]: 0
                            }
                        } else {
                            this.oPurchaseOrders = {
                                [this.Prop1]: this.oItemDashboardEdit.oFinalizedPurchaseOrders.oTotals.oMexicanPeso.dTotalAmount ? this.oItemDashboardEdit.oFinalizedPurchaseOrders.oTotals.oMexicanPeso.dTotalAmount : 0,
                                [this.Prop2]: 0
                            }
                            this.oWeighingMachinePublic = {
                                [this.Prop1]: this.oItemDashboardEdit.oFinalizedPublicOrders.oTotals.oMexicanPeso.dTotalAmount ? this.oItemDashboardEdit.oFinalizedPublicOrders.oTotals.oMexicanPeso.dTotalAmount : 0,
                                [this.Prop2]: 0
                            }
                            this.oSalesOrders = {
                                [this.Prop1]: this.oItemDashboardEdit.oFinalizedSalesOrders.oTotals.oMexicanPeso.dTotalAmount ? this.oItemDashboardEdit.oFinalizedSalesOrders.oTotals.oMexicanPeso.dTotalAmount : 0,
                                [this.Prop2]: 0
                            }

                            this.oPurchaseOrdersUSD = {
                                [this.Prop1]: this.oItemDashboardEdit.oFinalizedPurchaseOrders.oTotals.oUSDollar.dTotalAmount ? this.oItemDashboardEdit.oFinalizedPurchaseOrders.oTotals.oUSDollar.dTotalAmount : 0,
                                [this.Prop2]: 0
                            }
                            this.oWeighingMachinePublicUSD = {
                                [this.Prop1]: this.oItemDashboardEdit.oFinalizedPublicOrders.oTotals.oUSDollar.dTotalAmount ? this.oItemDashboardEdit.oFinalizedPublicOrders.oTotals.oUSDollar.dTotalAmount : 0,
                                [this.Prop2]: 0
                            }
                            this.oSalesOrdersUSD = {
                                [this.Prop1]: this.oItemDashboardEdit.oFinalizedSalesOrders.oTotals.oUSDollar.dTotalAmount ? this.oItemDashboardEdit.oFinalizedSalesOrders.oTotals.oUSDollar.dTotalAmount : 0,
                                [this.Prop2]: 0
                            }
                        }

                        break;

                    default:
                        break;
                }
            }
        },
        setLabelsDashboard() {
            this.sLabelOC1 = "";
            this.sLabelOC2 = "";
            this.sLabelWP1 = "";
            this.sLabelWP2 = "";
            this.sLabelPV1 = "";
            this.sLabelPV2 = "";
            switch (this.sNameRotue) {
                case "OPGeneral":
                    this.sLabelOC1 += " finalizadas"
                    this.sLabelOC2 += " pagadas"
                    this.sLabelWP1 += " finalizados"
                    this.sLabelWP2 += " cobradas"
                    this.sLabelPV1 += " finalizados"
                    this.sLabelPV2 += " cobrados"
                    break;
                case "OPPending":
                    this.sLabelOC1 += " pendientes"
                    this.sLabelWP1 += " pendientes"
                    // this.sLabelOC2 += " Pagadas"
                    this.sLabelPV1 += " pendientes"
                    // this.sLabelPV2 += " Cobrados"
                    break;
                case "OPPayable":
                    if (this.sTabPositionOrderPayable === 0) {
                        this.sLabelOC2 += " por pagar"
                        this.sLabelWP2 += " por cobrar"
                        this.sLabelPV2 += " por cobrar"
                    } else {
                        this.sLabelOC2 += " pagadas"
                        this.sLabelWP2 += " cobradas"
                        this.sLabelPV2 += " cobrados"
                    }

                    break;

                default:
                    break;
            }
        }
    },
    computed: {
        bOPGeneralView() {
            return this.$route.name === "OPGeneral";
        },
        bOPPendingView() {
            return this.$route.name === "OPPending";
        },
        bOPPayable() {
            return this.$route.name === "OPPayable";
        },
        sTabPositionOrderPayable() {
            return this.$store.state.sTabPositionOrderPayable;
        },
        sNameRotue() {
            return this.$route.name
        }
    },
    watch: {
        oItemDashboard() {
            this.setItemDashboard()
        },
        sTabPositionOrderPayable() {
            this.setLabelsDashboard();
        }
    }
}
</script>

<style>
.expansion-panels-op-dashboard{
    padding: 0 10px !important;
}
.expansion-panels-op-dashboard .v-expansion-panel {
    background-color: var(--primary-color-background-table) !important;
    color: var(--primary-color-color-title-information) !important;
}

.expansion-panels-op-dashboard .v-expansion-panel button {
    font-size: 20px !important;
}

.expansion-panels-op-dashboard .v-expansion-panel .v-expansion-panel-header__icon i {
    color: var(--primary-color-color-title-information) !important;
    font-size: 20px !important;
}

.expansion-panels-op-dashboard .v-expansion-panel .v-expansion-panel-content .v-expansion-panel-content__wrap {
    padding: 0 5px 16px !important;
}

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
    .demoColorResponsive {
        background-color: rgb(190, 136, 226);
    }
    .expansion-panels-op-dashboard{
    padding: 0 0px !important;
}
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
    .demoColorResponsive {
        background-color: rgb(136, 226, 151);
    }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
    .demoColorResponsive {
        background-color: rgb(201, 122, 83);
    }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(204, 198, 109);
    }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(113, 199, 201);
    }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>

<style scoped>
.content-filter-range-date {
    display: flex;
    justify-content: flex-end;
}

.content-dashboard-info {
    display: flex;
    margin-bottom: 20px;
}

.content-dashboard-oc,
.content-dashboard-so,
.content-dashboard-weighing-machine-public {
    width: 100%;
    height: 140px;
    margin-right: 10px;
    padding: 10px 10px 10px 10px;
    background-color: var(--primary-color-background-table) !important;
    border: 1px solid var(--primary-color-border-input);
    border-radius: 10px;
    display: block;
    align-items: center;
}


.content-dashboard-oc-OPPending,
.content-dashboard-oc-OPPayable,
.content-dashboard-weighing-machine-public-OPPending,
.content-dashboard-weighing-machine-public-OPPayable,
.content-dashboard-so-OPPending,
.content-dashboard-so-OPPayable {
    width: 100%;
    height: 85px;
    margin-right: 10px;
    padding: 10px 20px 10px 20px;
    background-color: var(--primary-color-background-table) !important;
    border: 1px solid var(--primary-color-border-input);
    border-radius: 10px;
    display: flex;
    align-items: center;
    text-align-last: center !important;
}

.content-finished {
    margin-right: .5%;
    width: 100%;
    min-width: 10%;
}

.content-paid {
    margin-left: .5%;
    width: 100%;
    min-width: 10%;

}


.content-icon-number {
    display: flex;
    align-items: center;
    place-content: start;
}

.content-icon-number-OPPending,
.content-icon-number-OPPayable {
    display: flex;
    align-items: center;
    place-content: start;
    justify-content: center !important;
}

.content-number {
    margin-left: 10px;
}

.btn-icon-pound {
    background-color: var(--primary-color-background-icon-status-blue) !important;
    width: 30px !important;
    max-width: 30px !important;
    min-width: 30px !important;
    height: 30px !important;
    max-height: 30px !important;
    min-height: 30px !important;
    border-radius: 100%;
}

.icon-pound-dash,
.btn-icon-currency {
    color: var(--primary-color-text-white) !important;
}

.btn-icon-currency {
    background-color: var(--primary-color-background-icon-status-green) !important;
    width: 30px !important;
    max-width: 30px !important;
    min-width: 30px !important;
    height: 30px !important;
    max-height: 30px !important;
    min-height: 30px !important;
    border-radius: 100%;
}

.number-text {
    font-family: 'pop-Bold';
    font-size: 16px;
    color: var(--primary-color-text);

}

.info-text {
    font-family: 'pop-Regular';
    font-size: 12px;
    color: var(--primary-color-text);
}

/*#region MODO RESPONSIVO */

/*#region XS */
@media (max-width: 599px) {
    .demoColorResponsive {
        background-color: rgb(190, 136, 226);
    }

    .content-op-dashboard {
        padding: 0px 10px 0px 10px;
    }

    .content-filter-range-date {
        display: flex;
        justify-content: flex-end;
    }

    .content-dashboard-info {
        display: block;
        margin-bottom: 20px;
    }


    .content-dashboard-oc,
    .content-dashboard-weighing-machine-public,
    .content-dashboard-so {
        width: 100%;
        height: 140px;
        margin-right: 10px;
        margin-bottom: 15px !important;
        padding: 10px 10px 10px 10px;
        background-color: var(--primary-color-background-table) !important;
        border: 1px solid var(--primary-color-border-input);
        border-radius: 10px;
        display: block;
        align-items: center;
    }

    .content-dashboard-oc-OPPending,
    .content-dashboard-weighing-machine-public-OPPending,
    .content-dashboard-so-OPPending {
        width: 100%;
        height: 80px;
        margin-right: 10px;
        margin-bottom: 15px !important;
        padding: 10px 20px 10px 20px;
        background-color: var(--primary-color-background-table) !important;
        border: 1px solid var(--primary-color-border-input);
        border-radius: 10px;
        display: block;
        align-items: center;
    }

    .content-dashboard-oc-OPPayable,
    .content-dashboard-weighing-machine-public-OPPayable,
    .content-dashboard-so-OPPayable {
        width: 100%;
        height: 80px;
        margin-right: 10px;
        margin-bottom: 15px !important;
        padding: 10px 20px 10px 20px;
        background-color: var(--primary-color-background-table) !important;
        border: 1px solid var(--primary-color-border-input);
        border-radius: 10px;
        display: block;
        align-items: center;
    }

    .content-finished {
        margin-right: 0%;
        width: 100%;
        min-width: 10%;
    }

    .content-paid {
        margin-top: 5px;
        margin-left: 0%;
        width: 100%;
        min-width: 10%;

    }


}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
    .demoColorResponsive {
        background-color: rgb(136, 226, 151);
    }

    .content-filter-range-date {
        display: flex;
        justify-content: flex-end;
    }

    .content-dashboard-info {
        display: block;
        /* margin-bottom: 20px; */
    }

    .content-dashboard-oc,
    .content-dashboard-weighing-machine-public,
    .content-dashboard-so {
        width: 100%;
        height: 140px;
        margin-right: 10px;
        margin-bottom: 15px !important;
        padding: 10px 20px 10px 20px;
        background-color: var(--primary-color-background-table) !important;
        border: 1px solid var(--primary-color-border-input);
        border-radius: 10px;
        display: block;
        align-items: center;
    }

    .content-dashboard-oc-OPPending,
    .content-dashboard-weighing-machine-public-OPPending,
    .content-dashboard-so-OPPending {
        width: 100%;
        height: 80px;
        margin-right: 10px;
        margin-bottom: 15px !important;
        padding: 10px 20px 10px 20px;
        background-color: var(--primary-color-background-table) !important;
        border: 1px solid var(--primary-color-border-input);
        border-radius: 10px;
        display: block;
        align-items: center;
    }

    .content-dashboard-oc-OPPayable,
    .content-dashboard-weighing-machine-public-OPPayable,
    .content-dashboard-so-OPPayable {
        width: 100%;
        height: 80px;
        margin-right: 10px;
        margin-bottom: 15px !important;
        padding: 10px 20px 10px 20px;
        background-color: var(--primary-color-background-table) !important;
        border: 1px solid var(--primary-color-border-input);
        border-radius: 10px;
        display: block;
        align-items: center;
    }

    .content-finished {
        margin-right: 0%;
        width: 100%;
        min-width: 10%;
    }

    .content-paid {
        margin-top: 5px;
        margin-left: 0%;
        width: 100%;
        min-width: 10%;

    }



}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
    .demoColorResponsive {
        background-color: rgb(201, 122, 83);
    }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(204, 198, 109);
    }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(113, 199, 201);
    }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO */
</style>